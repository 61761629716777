/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import {
  IonAvatar, IonButton, IonCard, IonCardContent,
} from '@ionic/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { AppContextState } from '../../../../state/AppContext';
import { userProfileImage } from '../../../../utils';
import expertProfileComplete from '../../../../utils/expertProfileComplete';

const ProfileCompletition: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const contextState = useContext(AppContextState);
  const { profile } = contextState;
  const { user_profile } = profile;

  if (!user_profile) return null;

  const { type, uuid } = user_profile;

  const percent = expertProfileComplete(user_profile);

  return (
    <IonCard id="profile-completition-card" className="ion-padding ion-no-margin">
      <IonCardContent className="profile-completition-content">
        <IonAvatar className="profile-completition-avatar">
          <img
            src={userProfileImage(type, uuid)}
            alt="user"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = 'assets/images/logo.png';
              currentTarget.style.objectFit = 'contain';
              currentTarget.style.background = '#ffffff';
              currentTarget.style.padding = '0.8rem';
            }}
          />
        </IonAvatar>
        <div className="profile-completition-body">
          {
              (percent < 100)
              && (
              <p className="description">
                <span>
                  {100 - percent}
                  %
                  {' '}
                  {t('percetnMissing')}
                </span>
                {' '}
                {t('completeProfile')}
              </p>
              )
            }
          {
              (percent >= 100)
              && (
              <p className="description">
                <span>{t('percetnComplete')}</span>
              </p>
              )
            }
          <IonButton data-testid="test-complete-redirect" shape="round" size="small" onClick={() => history.push('./profile')}>{t('goToProfile')}</IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default ProfileCompletition;
