/* eslint-disable camelcase */
import { AxiosPromise, AxiosRequestConfig } from 'axios';
import {
  Appoinment,
  ClientFindData,
  Logintype,
  NewUserObject,
  Sethistorysearch,
  Userprofile,
} from '../interfaces';
import {
  emailHasBeenValidated,
  emailValidation,
} from '../interfaces/loginInterfaces';
import AxiosInstance from './axios';

export const validateEmail = (userInformation: emailValidation) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(userInformation),
  };
  return AxiosInstance(
    'api/v1/authentications/before_login',
    fetchInformation,
  ) as AxiosPromise<emailHasBeenValidated>;
};

export const loginUser = (user: object) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(user),
  };
  return AxiosInstance(
    '/api/v1/authentications/login_verification',
    fetchInformation,
  );
};

export const registerUser = (user: NewUserObject) => {
  const newUser: NewUserObject = {
    provider: 'email',
    user_type: 'client',
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
  };
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(newUser),
  };
  return AxiosInstance('/api/v1/authentications/email_signup', fetchInformation);
};

export const retiveUser = (userData: Logintype) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(userData),
  };
  return AxiosInstance('/api/v1/authentications', fetchInformation);
};

export const getUserToken = async (userData: Logintype) => {
  const auth = {
    provider: userData.provider,
    id: userData.id,
    email: userData.email,
    token: userData.token,
    user_type: userData.user_type,
  };
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ auth }),
  };
  return AxiosInstance('/api/v1/user_token', fetchInformation);
};

export const logoutUser = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${JSON.parse(jwt)}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/sessions', fetchInformation);
};

export const userProfile = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/clients', fetchInformation);
};

export const getIndustries = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/industries/', fetchInformation);
};

export const getServices = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/services', fetchInformation);
};

export const getSeniority = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/seniority_levels/', fetchInformation);
};

export const getJobFunctions = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/job_functions/', fetchInformation);
};

export const getLanguages = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/languages/', fetchInformation);
};

export const getTimeZone = async (jwt: string, timeZone: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/api/v1/timezones/${timeZone}`, fetchInformation);
};

export const updateUserProfile = async (userData: Userprofile, jwt: string) => {
  const userD = { ...userData };
  if (userD.industries) {
    userD.work_histories = userD.industries;
    delete userD.industries;
  }
  const fetchInformation: AxiosRequestConfig = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(userD),
  };
  return AxiosInstance('/api/v1/clients', fetchInformation);
};

export const searchExpert = async (
  searchData: ClientFindData,
  jwt: string,
  percentil = 0.5,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(searchData),
  };
  return AxiosInstance(
    `/api/v1/search_experts?top_percentile:${percentil}`,
    fetchInformation,
  );
};

export const getExpertAvailabilities = async (
  jwt: string,
  expertId: number,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/experts/availabilities?expert_id=${expertId}`,
    fetchInformation,
  );
};

export const getExpertAvailabilitiesForAppointments = async (
  jwt: string,
  expertId: number,
  interval: string,
  date: string,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/expert_appointments/I=${expertId}&interval=${interval}&date=${date}`,
    fetchInformation,
  );
};

export const setAppointment = async (
  jwt: string,
  userId: number,
  userType: string,
  appointment: object,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(appointment),
  };
  return AxiosInstance(
    `/api/v1/appointments?by=${userType}&user_id=${userId}`,
    fetchInformation,
  );
};

export const setAppointmentForPushNotifications = async (
  jwt: string,
  appointment: Appoinment,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(appointment),
  };
  return AxiosInstance('/api/v1/payments/', fetchInformation);
};

export const deleteAppointment = async (jwt: string, id: number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/api/v1/appointments/${id}`, fetchInformation);
};

export const setSearchHistories = async (
  jwt: string,
  search_history: Sethistorysearch,
) => {
  const history = { search_history };
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(history),
  };
  return AxiosInstance('/api/v1/search_histories', fetchInformation);
};

export const setClientPayment = async (jwt: string, payment: object) => {
  const information = { payment };
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(information),
  };
  return AxiosInstance('/api/v1/payments', fetchInformation);
};

export const updateAppointment = async (jwt: string, event: any) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(event),
  };
  return AxiosInstance(`/api/v1/appointments/${event.id}`, fetchInformation);
};

export const getAllAppointments = async (
  jwt: string,
  userId: number,
  userType: string,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/appointments?by=${userType}&user_id=${userId}`,
    fetchInformation,
  );
};

export const getSingleAppointment = async (jwt: string, id: number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(`/api/v1/appointments/${id}`, fetchInformation);
};

export const getAppointmentsStatus = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/appointments/status_by_user', fetchInformation);
};

export const rateUser = async (jwt: string, review: object) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ review }),
  };
  return AxiosInstance('/api/v1/reviews', fetchInformation);
};

export const getUserRates = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/reviews', fetchInformation);
};

export const getExpertByIdRates = async (jwt: string, expertId: number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/reviews/${expertId}/user_reviews`,
    fetchInformation,
  );
};

export const getUserInvoices = async (jwt: string, id: number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/invoices/${id}/invoices_to_client`,
    fetchInformation,
  );
};

export const getExpertsCommunity = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/clients/expert_community', fetchInformation);
};

export const getExpertsByID = async (jwt: string, userId: number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/experts/expert_by_id/${userId}`,
    fetchInformation,
  );
};

export const requestInvoice = async (jwt: string, userId: number) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/invoices/${userId}/generate_invoice`,
    fetchInformation,
  );
};

export const requestExpertTaxes = async (
  jwt: string,
  expertId: number,
  serviceId: number,
) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance(
    `/api/v1/clients/service_prices_and_taxes?expert_id=${expertId}&service_id=${serviceId}`,
    fetchInformation,
  );
};

export const requestClientPayments = async (jwt: string) => {
  const fetchInformation: AxiosRequestConfig = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  };
  return AxiosInstance('/api/v1/clients/payments', fetchInformation);
};
