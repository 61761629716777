/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IonCol } from '@ionic/react';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LoginImage } from '../../../assets/images/login.svg';

interface ComponentProps {
  // eslint-disable-next-line no-unused-vars
  showTerms: Dispatch<SetStateAction<boolean>>;
  background: string;
}

const Slider: React.FC<ComponentProps> = ({
  showTerms,
  background,
}) => {
  const { t } = useTranslation();
  return (
    <IonCol
      data-testid="test-login-slider"
      size="12"
      sizeMd="12"
      sizeLg="6"
      className={`login ${background} ion-hide-sm-down`}
      style={{ minHeight: '100vh' }}
    >
      <div className="header">
        <LoginImage />
      </div>
      <p data-testid="test-terms-modal" className="termsAnchor" onClick={() => showTerms(true)}>
        {t('Terms')}
      </p>
    </IonCol>
  );
};

export default Slider;
