import {
  IonCard, IonCardContent, IonCol, IonGrid, IonIcon, IonRow,
} from '@ionic/react';
import { settingsOutline, newspaperOutline, logOutOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import './settings.scss';

interface ComponentProps {
  logout: () => void;
}

const Settings: React.FC<ComponentProps> = ({ logout }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <IonGrid id="settingsPage">
      <IonRow>
        <IonCol size="12">
          <IonCard data-testid="test-contact-button" onClick={() => {}}>
            <IonCardContent>
              <p>
                {' '}
                <IonIcon icon={settingsOutline} />
                {' '}
                {t('US_L4')}
              </p>
            </IonCardContent>
          </IonCard>
          <IonCard data-testid="test-billing-button" onClick={() => history.push('./billing')}>
            <IonCardContent>
              <p>
                {' '}
                <IonIcon icon={newspaperOutline} />
                {' '}
                {t('US_L10')}
              </p>
            </IonCardContent>
          </IonCard>
          <IonCard data-testid="log-out" onClick={() => logout()}>
            <IonCardContent>
              <p>
                <IonIcon icon={logOutOutline} />
                {t('US_L3')}
              </p>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Settings;
