import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContextState } from '../../state/AppContext';
import './loader.scss';

const Loader = () => {
  const { t } = useTranslation();
  const { general } = useContext(AppContextState);
  return (
    <div className={`loaderContainer ${general.loader ? '' : 'hidden'}`}>
      <div className="cube-wrapper">
        <div className="cube-folding">
          <span className="leaf1" />
          <span className="leaf2" />
          <span className="leaf3" />
          <span className="leaf4" />
        </div>
        <span className="loading" data-name="Loading">
          {t('loading')}
        </span>
      </div>
    </div>
  );
};

export default Loader;
