/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import {
  IonMenu, IonHeader, IonContent, IonList, IonItem,
} from '@ionic/react';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { menuController } from '@ionic/core';
import menuData from '../../constants/inner-routes.json';
import { InnerRouteIf } from '../../interfaces';
import ImageHandler from '../ImageHandler/ImageHandler';
import './menu.scss';
import MenuUserCard from './MenuUserCard';
import { AppContextState } from '../../state/AppContext';

const Menu: React.FC<{}> = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);
  const { login, profile } = contextState;
  const { user_profile } = profile;

  const userInformation = {
    userName: `${user_profile.first_name} ${user_profile.last_name}`,
    userUuid: user_profile.uuid,
    userType: user_profile.type,
  };

  const closeMenu = () => {
    menuController.close('first');
  };

  return (
    <IonMenu
      className={!login.login ? 'hidden' : ''}
      contentId="main-menu"
      side="start"
      type="overlay"
      menuId="first"
    >
      <IonHeader className="ion-padding ion-no-border">
        <MenuUserCard {...userInformation} />
      </IonHeader>
      <IonContent id="main-menu">
        <IonList>
          {menuData
            && menuData.map((item: InnerRouteIf, index: number) => (item.userType === userInformation.userType || item.userType === 'all' ? (
              <IonItem data-testid={`menu-item-${index}`} onClick={() => closeMenu()} key={item.id} className="menu-item">
                <NavLink
                  activeClassName="active"
                  to={`/user${item.redirectTo}`}
                >
                  {ImageHandler({ imageName: item.icon, extension: 'svg' })}
                  <span slot="end">
                    {' '}
                    {t(item.name)}
                  </span>
                </NavLink>
              </IonItem>
            ) : null))}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
