/* eslint-disable camelcase */
import { IonPage, IonContent } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import ErrorModal from '../../components/error/ErrorModal';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import ModalWrapper from '../../components/modalWrapper/ModalWrapper';
import {
  GET_USER_PROFILE,
  LOADER,
  LOGIN,
  LOGOUT,
} from '../../constants/constants';
import {
  General, Login, Logintype, Profile,
} from '../../interfaces';
import Availabilitites from '../../pages/Availabilities/Availabilitites';
import BillingInformation from '../../pages/billing/BillingInformation';
import ClientDashboard from '../../pages/dashboard/ClientDashboard';
import ExpertDashboard from '../../pages/dashboard/ExpertDashboard';
import BlogView from '../../pages/library/components/BlogView';
import Library from '../../pages/library/Library';
import ClientProfile from '../../pages/profile/ClientProfile';
import ExpertProfile from '../../pages/profile/ExpertProfile';
import RequestExpert from '../../pages/requestService.tsx/RequestExpert';
import RequestService from '../../pages/requestService.tsx/RequestService';
import SearchExpert from '../../pages/searchExpert/SearchExpert';
import SearchExpertFilters from '../../pages/searchExpertFilters/SearchExpertFilters';
import ServiceMeeting from '../../pages/service_meeting/ServiceMeeting';
import Statistics from '../../pages/statistics/Statistics';
import Settings from '../../pages/userSettings/Settings';
import { logoutUser, userProfile } from '../../services/client';
import { userExpertProfile } from '../../services/expert';
import { AppContextDispatch, AppContextState } from '../../state/AppContext';
import initialState from '../../state/InitialState';
import './AppFrame.scss';

const AppFrame: React.FC = () => {
  const contextDispatch = useContext(AppContextDispatch);
  const contextState = useContext(AppContextState);
  const history = useHistory();
  const [errorModal, setErrorModal] = useState(false);
  const { profile } = contextState;
  const { user_profile } = profile;

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  useEffect(() => {
    const userData = sessionStorage.getItem('userData');
    validateUser(JSON.parse(userData || '{}'));
  }, []);

  const handleError = () => {
    setErrorModal(true);
    dispatchLoader(false);
  };

  const validateUser = async (userData: Logintype) => {
    const shouldKeepLoged = localStorage.getItem('logon');
    if (!userData.id && !shouldKeepLoged) {
      logOutUserFunction();
    } else if (!userData.id && shouldKeepLoged) {
      history.push('/');
    } else {
      const jwt = window.sessionStorage.getItem('jwt') || '';
      try {
        if (userData.user_type === 'Expert') {
          await userExpertProfile(jwt)
            .then(({ data: expertProfile }) => {
              const payload: Profile = {
                user_profile: expertProfile,
              };
              contextDispatch({
                type: GET_USER_PROFILE,
                payload,
              });
              userIsLogged(expertProfile);
            })
            .catch(() => handleError());
        } else {
          await userProfile(jwt)
            .then(({ data: expertProfile }) => {
              const payload: Profile = {
                user_profile: expertProfile,
              };
              contextDispatch({
                type: GET_USER_PROFILE,
                payload,
              });
              userIsLogged(expertProfile);
            })
            .catch(() => handleError());
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const userIsLogged = (user: Logintype) => {
    const payload: Login = {
      login: true,
      user_auth: '',
      login_type: user,
    };
    contextDispatch({
      type: LOGIN,
      payload,
    });
  };

  const logOutUserFunction = async () => {
    const userAuth = sessionStorage.getItem('userAuth') || '';
    dispatchLoader(true);
    try {
      await logoutUser(userAuth)
        .then((response) => {
          if (response) {
            const payload: Login = {
              user_auth: '',
              login: false,
              login_type: initialState.login.login_type,
            };
            contextDispatch({
              type: LOGOUT,
              payload,
            });
            sessionStorage.clear();
            history.push('/');
            localStorage.removeItem('logon');
            window.location.reload();
          }
        })
        .catch(() => {
          sessionStorage.clear();
          localStorage.removeItem('logon');
          history.push('/');
          window.location.reload();
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonPage id="main-content">
      <Header />
      <IonContent data-testid="ion-content-test" className="ion-padding">
        <Switch>
          <Route exact path="/user/dashboard">
            { user_profile.type === 'Client' && <ClientDashboard /> }
            { user_profile.type === 'Expert' && <ExpertDashboard /> }
          </Route>
          <Route exact path="/user/profile">
            { user_profile.type === 'Client' && <ClientProfile /> }
            { user_profile.type === 'Expert' && <ExpertProfile /> }
          </Route>
          <Route exact path="/user/statistic">
            <Statistics />
          </Route>
          <Route exact path="/user/availabilities">
            <Availabilitites />
          </Route>
          <Route exact path="/user/library/:slug" render={() => <BlogView />} />
          <Route exact path="/user/library">
            <Library />
          </Route>
          <Route exact path="/user/settings">
            <Settings logout={logOutUserFunction} />
          </Route>
          <Route exact path="/user/billing">
            <BillingInformation />
          </Route>
          <Route exact path="/user/service_meeting/:id">
            <ServiceMeeting />
          </Route>
          <Route exact path="/user/search">
            <SearchExpert />
          </Route>
          <Route exact path="/user/request_expert/:id">
            <RequestExpert />
          </Route>
          <Route exact path="/user/request_service/:id">
            <RequestService />
          </Route>
          <Route exact path="/user/:functionSearched/:skillSearched">
            <SearchExpertFilters />
          </Route>
          <Route exact path="*">
            <>no existe aqui</>
          </Route>
        </Switch>
      </IonContent>
      <Footer />
      <ModalWrapper wrapperClass="ErrorModal" data-testid="error-modal-test" isOpen={errorModal} setIsOpen={() => setErrorModal(false)}>
        <ErrorModal />
      </ModalWrapper>
    </IonPage>
  );
};

export default AppFrame;
