/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import {
  IonAvatar,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LOADER } from '../../constants/constants';
import { General, Userprofile } from '../../interfaces';
import { AppointmentDetail } from '../../interfaces/clientInterfaces';
import { getExpertsByID } from '../../services/client';
import { getClientsByID } from '../../services/expert';
import { AppContextDispatch, AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import Languages from './Languages';
import WorkExperience from './WorkExperience';

interface ComponentProps {
  appointmentDetail: AppointmentDetail | undefined;
}
type UserType = Userprofile | undefined;

const AppoinmentModal: React.FC<ComponentProps> = ({
  appointmentDetail,
}) => {
  const { t } = useTranslation();
  const contextDispatch = useContext(AppContextDispatch);
  const contextState = useContext(AppContextState);

  const { profile } = contextState;
  const { user_profile } = profile;

  const [modalUser, setModalUser] = useState<UserType>(undefined);

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const handleError = () => {
    dispatchLoader(true);
  };

  // TODO: handle error response
  useEffect(() => {
    dispatchLoader(true);
    const jwt: string = sessionStorage.getItem('jwt') || '';
    if (user_profile.type === 'Client') {
      getExpertsByID(jwt, appointmentDetail?.expert_id || 0)
        .then(({ data }) => {
          setModalUser(data);
          dispatchLoader(false);
        })
        .catch(() => {});
    } else {
      getClientsByID(jwt, appointmentDetail?.user_id || 0)
        .then(({ data }) => {
          setModalUser(data);
          dispatchLoader(false);
        })
        .catch(() => handleError());
    }
    return (() => {
      setModalUser(undefined);
    });
  }, []);

  const renderModalBody = () => {
    const full_name = `${modalUser?.first_name} ${modalUser?.last_name}`;
    const questions = appointmentDetail?.service_request || [];
    let question_title = '';
    return (
      <IonGrid>
        <IonRow>
          <IonCol>
            <div data-testid="test-profile-banner" className="profileBanner">
              <IonAvatar className="rounded-circle image-big">
                <img
                  src={userProfileImage(modalUser?.type || '', modalUser?.uuid || '')}
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = 'assets/images/logo.png';
                    currentTarget.style.objectFit = 'contain';
                    currentTarget.style.background = '#ffffff';
                    currentTarget.style.padding = '0.8rem';
                  }}
                />
              </IonAvatar>
            </div>
            <IonCard>
              <IonCardHeader>
                <IonCardTitle>{t('EMP_L5', { full_name })}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <div className="about ion-margin-bottom ion-text-start">
                  {modalUser?.about.aboutBody}
                </div>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol id="accordion" className="ion-no-padding ion-margin-bottom">
            <Languages languagesArray={modalUser?.languages_skill} />
            <WorkExperience works={modalUser?.work_histories} />
            {
              Object.entries(questions).map((element, index) => {
                if (element[0] === 'needMentory') question_title = 'needMentory';
                else if (element[0] === 'searchMentory') question_title = 'searchMentory';
                else if (element[0] === 'searchExpressAdvice') question_title = 'searchExpressAdvice';
                else if (element[0] === 'coachType') question_title = 'coachType';
                else if (element[0] === 'coachAchives') question_title = 'coachAchives';
                else if (element[0] === 'coachChanges') question_title = 'coachChanges';
                else if (element[0] === 'searchCoaching') question_title = 'searchCoaching';
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <IonCard className="questions_answers ion-padding" key={index}>
                    <IonCardHeader className="section-title ion-text-start">
                      <h2>{t(question_title)}</h2>
                      <span className="title-border" />
                    </IonCardHeader>
                    <IonCardContent className="ion-text-start">
                      {element[1]}
                    </IonCardContent>
                  </IonCard>
                );
              })
            }
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };

  return (
    <IonContent id="ExpertsInfoModal">
      {modalUser && renderModalBody()}
    </IonContent>
  );
};

export default AppoinmentModal;
