/* eslint-disable no-param-reassign */
/* istanbul ignore file */
import {
  IonButton, IonContent, IonGrid, IonRow, IonText,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import aes from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import Description from './components/Description';
import Header from './components/Header';
import Slider from './components/Slider';
import './login.scss';
import { AppContextDispatch } from '../../state/AppContext';
import { GET_USER_PROFILE, LOADER, LOGIN } from '../../constants/constants';
import {
  General,
  Login as LoginObject,
  Logintype,
  Profile,
} from '../../interfaces';
import { getUserToken, retiveUser, userProfile } from '../../services/client';
import expertProfileComplete from '../../utils/expertProfileComplete';
// import clientProfileComplete from '../../utils/clientProfileComplete';
import ErrorModal from '../../components/error/ErrorModal';
import { getCookie, validateUserBrowser, welcomeUser } from '../../utils';
import BrowserModal from '../../components/browserModal/BrowserModal';
import TermsModal from '../../components/termsModal/TermsModal';
import PrivacyModal from '../../components/privacyModal/PrivacyModal';
import { userExpertProfile } from '../../services/expert';
import ModalWrapper from '../../components/modalWrapper/ModalWrapper';

const Login: React.FC<{}> = () => {
  const { t, i18n } = useTranslation();
  // AppContext
  const contextDispatch = useContext(AppContextDispatch);
  // Local State
  const [isChromeBrowser, setIsChromeBrowser] = useState(false);
  const [privacyShow, setPrivacyShow] = useState(false);
  const [termsModal, setTermsModal] = useState(false);
  const [background, setBackground] = useState('SearchExpert');
  const [logginAs, setLogginAs] = useState<'SearchExpert' | 'Expert'>('SearchExpert');
  const [clientType, setClientType] = useState('SearchExpert');
  const [errorModal, setErrorModal] = useState(false);
  const [cookie, setCookie] = useState(false);
  const [keepLoged, setKeepLoged] = useState(true);
  const [encKey] = useState(process.env.REACT_APP_ENCRYPTON_KEY || '');
  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState('');

  const params = QueryString.parse(window.location.search);
  const history = useHistory();
  const location = useLocation();

  const dispatchLoader = (value: boolean) => {
    const payload: General = {
      loader: value,
    };
    contextDispatch({
      type: LOADER,
      payload,
    });
  };

  const showPrivacy = () => {
    setPrivacyShow(!privacyShow);
  };

  const loginUserType = (value: 'SearchExpert' | 'Expert') => {
    setLogginAs(value);
    setBackground(value);
  };

  const encryptAndSave = (userData: any) => {
    const encrypted = aes.encrypt(JSON.stringify(userData), encKey);
    localStorage.setItem('logon', encrypted.toString());
  };

  const decryptAndLogin = (data: any) => {
    dispatchLoader(true);
    const decrypted = aes.decrypt(data, encKey);
    const user = JSON.parse(decrypted.toString(enc));
    if (user.userType === 'Client') {
      loginUserType('SearchExpert');
    }
    if (user.userType === 'Expert') {
      loginUserType('Expert');
    }
    onSuccess(user);
  };

  const onSuccess = async (userRecived: Logintype, type?: string) => {
    const payload: LoginObject = {
      login: true,
      user_auth: '',
      login_type: userRecived,
    };
    if (userRecived.user_type === undefined) {
      userRecived.user_type = clientType;
    }
    if (keepLoged) encryptAndSave({ ...userRecived });
    if (userRecived.id) {
      if (type === 'email') { userRecived.provider = type; }
      retiveUser(userRecived)
        .then(({ data: userBE }) => {
          userBE.user_type = userBE.type;
          userBE.token = userRecived.token || '';
          userBE.id = userRecived.facebook_uid || userRecived.linkedin_uid;
          getUserToken(userBE)
            .then(({ data: token }) => {
              window.sessionStorage.setItem('jwt', token.jwt);
              payload.user_auth = token.jwt;
              contextDispatch({
                type: LOGIN,
                payload,
              });
              authenticateUser(userBE, token.jwt);
            })
            .catch(() => handleError());
        })
        .catch(() => handleError());
    }
  };

  const handleError = () => {
    setErrorModal(true);
    dispatchLoader(false);
    sessionStorage.clear();
  };

  const onFailure = () => {
    handleError();
  };

  const acceptCookies = () => {
    const d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
    const expires = d.toUTCString();
    document.cookie = `cookiesAccepted=${new Date()};expires=${expires};path=/`;
    setCookie(true);
  };

  useEffect(() => {
    const userData = window.sessionStorage.getItem('userData');
    const userIsAuthenticated = window.sessionStorage.getItem('userAuth');
    if (userData && userIsAuthenticated) {
      authenticateUser(JSON.parse(userData), JSON.parse(userIsAuthenticated));
    }
    const isReady = getCookie('cookiesAccepted');
    setCookie(!!isReady);
    setIsChromeBrowser(!validateUserBrowser());
    const shouldKeepLoged = localStorage.getItem('logon');
    if (shouldKeepLoged) decryptAndLogin(shouldKeepLoged);
  }, []);

  useEffect(() => {
    if (logginAs === 'Expert') setClientType('Expert');
    else setClientType('Client');
  }, [logginAs]);

  useEffect(() => {
    if (location.search) {
      const queryString = location.search;
      const urlParams = new URLSearchParams(queryString);
      const userType = urlParams.get('userType');
      if (userType === 'Client') {
        loginUserType('SearchExpert');
      }
      if (userType === 'Expert') {
        loginUserType('Expert');
      }
    }
  }, [location.search]);

  const authenticateUser = async (userData: Logintype, userAuth: string) => {
    window.sessionStorage.setItem('userAuth', JSON.stringify(userAuth));
    window.sessionStorage.setItem('userData', JSON.stringify(userData));
    const userWelcomed = sessionStorage.getItem('userWelcomed') || undefined;
    const jwt = userAuth;
    if (userData.user_type === 'Expert') {
      userExpertProfile(jwt)
        .then(({ data: expertProfile }) => {
          const payload: Profile = {
            user_profile: expertProfile,
          };
          contextDispatch({
            type: GET_USER_PROFILE,
            payload,
          });
          const totalPercent = expertProfileComplete(expertProfile);
          if (totalPercent === 100) history.push('/user/dashboard');
          else history.push('/user/profile');
        })
        .catch(() => handleError());
    } else {
      userProfile(jwt)
        .then(({ data: clientProfile }) => {
          const payload: Profile = {
            user_profile: clientProfile,
          };
          contextDispatch({
            type: GET_USER_PROFILE,
            payload,
          });
          history.push('/user/search');
          // const totalPercent = clientProfileComplete(clientProfile);
          // if (totalPercent === 100) history.push('/user/dashboard');
          // else history.push('/user/profile');
        })
        .catch(() => handleError());
    }
    if (userData.is_new_user && !userWelcomed) welcomeUser(i18n.language);
    window.sessionStorage.setItem('userWelcomed', JSON.stringify('userWelcomed'));
    dispatchLoader(false);
  };

  return (
    <>
      <Header />
      <IonContent>
        <IonGrid className="ion-no-padding login-wrapper">
          <IonRow>
            <Slider
              showTerms={setTermsModal}
              background={background}
            />
            <Description
              onSuccess={onSuccess}
              onFailure={onFailure}
              showPrivacy={showPrivacy}
              loginUserType={loginUserType}
              logginAs={logginAs}
              background={background}
              params={params}
              keepLoged={keepLoged}
              setKeepLoged={(value: boolean) => setKeepLoged(value)}
            />
          </IonRow>
        </IonGrid>
        <div
          className={`cookiesContainer ${cookie !== true ? '' : ' hidden'}`}
        >
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <p>
              {t('cookiesText')}
              {' '}
              <IonText onClick={() => showPrivacy()}>{t('knowMore')}</IonText>
              &nbsp;&nbsp;
            </p>
            <IonButton
              color="secondary"
              shape="round"
              onClick={() => acceptCookies()}
              className="ion-margin"
            >
              {t('accept')}
            </IonButton>
          </IonRow>
        </div>
        <ModalWrapper
          wrapperClass="ErrorModal"
          isOpen={errorModal}
          setIsOpen={() => setErrorModal(false)}
        >
          <ErrorModal error={errorMessage} />
        </ModalWrapper>
        <ModalWrapper
          isOpen={isChromeBrowser}
          setIsOpen={() => setIsChromeBrowser(false)}
        >
          <BrowserModal />
        </ModalWrapper>
        <ModalWrapper
          wrapperClass="borderModal"
          isOpen={termsModal}
          setIsOpen={() => setTermsModal(false)}
        >
          <TermsModal />
        </ModalWrapper>
        <ModalWrapper
          wrapperClass="borderModal"
          isOpen={privacyShow}
          setIsOpen={() => setPrivacyShow(false)}
        >
          <PrivacyModal />
        </ModalWrapper>
      </IonContent>
    </>
  );
};

export default Login;
