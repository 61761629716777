import React, { useEffect, useReducer } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import './theme/globalTheme.scss';
import 'react-tippy/dist/tippy.css';
import 'react-calendar/dist/Calendar.css';

import { useTranslation } from 'react-i18next';
import AppFrame from './layouts/AppFrame/AppFrame';
import Menu from './components/menu/menu';
import Login from './pages/login/Login';
import { AppContextDispatch, AppContextState } from './state/AppContext';
import reducers from './state/reducers';
import initialState from './state/InitialState';
import Loader from './components/loader/Loader';
import Privacy from './layouts/Privacy/Privacy';

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducers, initialState);

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes('es')) {
      changeLanguage('es');
    }
  }, []);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <AppContextState.Provider value={state}>
      <AppContextDispatch.Provider value={dispatch}>
        <IonApp>
          <IonReactRouter>
            <IonSplitPane contentId="main" id="main-content" when="lg">
              <Menu />
              <IonRouterOutlet id="main">
                <Switch>
                  <Route path="/user">
                    <AppFrame />
                  </Route>
                  <Route path="/privacy">
                    <Privacy />
                  </Route>
                  <Route exact path="/">
                    <Login />
                  </Route>
                  <Route path="*">
                    <Redirect to={{ pathname: '/' }} />
                  </Route>
                </Switch>
              </IonRouterOutlet>
            </IonSplitPane>
          </IonReactRouter>
        </IonApp>
        <Loader />
      </AppContextDispatch.Provider>
    </AppContextState.Provider>
  );
};

export default App;
