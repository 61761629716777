import { IonIcon } from '@ionic/react';
import { logoChrome } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BrowserModal: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="warningContainer">
      <h3>
        <IonIcon
          style={{ width: '50px', height: '50px' }}
          color="secondary"
          icon={logoChrome}
        />
      </h3>
      <div>
        <p style={{ fontWeight: 'normal' }}>{t('browserValidator')}</p>
      </div>
    </div>
  );
};

export default BrowserModal;
