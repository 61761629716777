/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* istanbul ignore file */
import { IonButton, IonIcon } from '@ionic/react';
import React, { Component } from 'react';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { logoLinkedin } from 'ionicons/icons';

class LinkedInPage extends Component {
  getUserProfile = (token) => {
    const params = {
      provider: 'linkedin',
      first_name: null,
      last_name: null,
      email: null,
      id: null,
      linkedin_uid: null,
      facebook_uid: null,
      picture: null,
    };
    const newInfo = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const proxyurl = process.env.REACT_APP_PROXY_LINKEDIN;
    const url = 'https://api.linkedin.com/v2/me'
      + '?projection=(id,localizedFirstName,localizedLastName,'
      + 'email-address,profilePicture(displayImage~:playableStreams))';
    fetch(proxyurl + url, newInfo)
      .then((resp) => resp.json())
      .then((response) => {
        if (response.id) {
          fetch(
            `${proxyurl}https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))`,
            newInfo,
          )
            .then((mailRes) => mailRes.json())
            .then((mailResponse) => {
              if (mailResponse.elements[0]['handle~']) {
                const { profilePicture } = response;
                const images = profilePicture['displayImage~'];
                const { elements } = images;
                const identifiers = elements[elements.length - 1].identifiers[0];
                const userPicture = identifiers.identifier;
                const pictureData = elements[elements.length - 1].data[
                  'com.linkedin.digitalmedia.mediaartifact.StillImage'
                ];
                const sizes = pictureData.storageSize;
                const picture = {
                  is_silhouette: false,
                  width: sizes.width,
                  height: sizes.height,
                  url: userPicture,
                };
                params.first_name = response.localizedFirstName;
                params.last_name = response.localizedLastName;
                params.email = mailResponse.elements[0]['handle~'].emailAddress;
                params.id = response.id;
                params.linkedin_uid = response.id;
                params.token = token;
                params.picture = picture;
                this.props.onSuccess(params);
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log('error: ', err));
  };

  getValidToken = (code) => {
    const fetchInformation = {
      method: 'POST',
    };
    const proxyurl = process.env.REACT_APP_PROXY_LINKEDIN;
    const url = `https://www.linkedin.com/oauth/v2/accessToken?client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&client_secret=${process.env.REACT_APP_LINKED_IN_SECRET}&grant_type=authorization_code&code=${code}&redirect_uri=${process.env.REACT_APP_LINKED_IN_URL_CALLBACK}`;
    fetch(proxyurl + url, fetchInformation)
      .then((res) => res.json())
      .then((response) => {
        if (response.access_token) {
          this.getUserProfile(response.access_token);
        }
      })
      .catch((err) => console.log(err));
  };

  handleSuccess = (data) => {
    if (data.code) {
      this.getValidToken(data.code);
    }
  };

  handleFailure = (error) => {
    console.log(error);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <LinkedIn
          clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
          onFailure={this.handleFailure}
          onSuccess={this.handleSuccess}
          scope="r_liteprofile,r_emailaddress"
          redirectUri={process.env.REACT_APP_LINKED_IN_URL_CALLBACK}
          redirectPath="/linkedin"
          renderElement={({ onClick, disabled }) => (
            <IonButton
              data-testid="test-linkedin-login"
              className="social-button"
              fill="outline"
              expand="block"
              onClick={() => {
                this.props.setLoader(true);
                onClick();
              }}
              disabled={disabled}
            >
              <IonIcon slot="start" icon={logoLinkedin} />
              {t('continueLinked')}
            </IonButton>
          )}
        />
      </>
    );
  }
}

export default LinkedInPage;
