import React from 'react';
import {
  IonContent, IonPage,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Header from '../../components/header/Header';

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <IonPage id="main-content">
      <Header />
      <IonContent className="ion-padding">
        <p dangerouslySetInnerHTML={{ __html: t('PrivacyText') }} />
      </IonContent>
    </IonPage>
  );
};

export default Privacy;
