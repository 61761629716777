/* eslint-disable no-param-reassign */
import {
  IonCol,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonIcon,
  IonRow,
  IonAvatar,
  IonCardSubtitle,
} from '@ionic/react';
import { locationOutline, rocketOutline, starOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ExpertDetail } from '../../interfaces/clientInterfaces';
import { userProfileImage } from '../../utils';

interface ComponentProps {
  expert: ExpertDetail;
}

const ResultCard: React.FC<ComponentProps> = ({ expert }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const rate: any = expert.review_rate ? expert.review_rate : 0;

  const navigateToExpert = () => {
    history.push(`/user/request_expert/${expert.id}`);
  };

  return (
    <IonRow
      className="ion-align-items-center ion-padding ion-margin-top border-secondary"
    >
      <IonCol className="ion-text-center" size="12" sizeMd="3" sizeLg="2">
        <IonAvatar className="userProfileImage">
          <img
            src={userProfileImage('Expert', expert.uuid)}
            alt="user"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = 'assets/images/logo.png';
              currentTarget.style.objectFit = 'contain';
              currentTarget.style.background = '#ffffff';
              currentTarget.style.padding = '0.8rem';
            }}
          />
        </IonAvatar>
      </IonCol>
      <IonCol size="12" sizeMd="9" sizeLg="8">
        <IonCardHeader className="ion-no-padding">
          <IonCardTitle>{expert.first_name}</IonCardTitle>
          <IonCardSubtitle>
            <IonIcon icon={locationOutline} />
            &nbsp;
            {expert.contact_information.location}
            {rate > 3.5 && (
              <>
                &nbsp;&nbsp;&nbsp;
                <IonIcon icon={starOutline} />
                &nbsp;
                {rate.toFixed(1)}
              </>
            )}
          </IonCardSubtitle>
        </IonCardHeader>
        <p>{expert.about.aboutBody}</p>
      </IonCol>
      <IonCol className="ion-text-center" size="12" sizeMd="12" sizeLg="2">
        <IonButton
          className="ion-margin-bottom buttonAction"
          color="primary"
          shape="round"
          size="small"
          expand="full"
          onClick={() => history.push(`/user/request_service/${expert.id}`)}
        >
          <IonIcon icon={rocketOutline} />
          &nbsp;
          {t('serviceModal')}
        </IonButton>
        <IonButton
          className="buttonAction"
          color="secondary"
          shape="round"
          fill="outline"
          size="small"
          expand="full"
          onClick={() => navigateToExpert()}
        >
          {t('EC_L1')}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

export default ResultCard;
