import { menuController } from '@ionic/core';
import {
  IonHeader,
  IonToolbar,
  IonImg,
  IonButton,
  IonIcon,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../../../components/languageSelector/LanguageSelector';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [showSelector, setShowSelector] = useState(false);

  useEffect(() => {
    menuController.enable(true, 'first');
    return () => {
      menuController.enable(false, 'first');
    };
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonImg
            slot="start"
            className="ion-padding-start svg-header"
            src="assets/images/logo_horizontal.svg"
          />
          <IonButton
            data-testid="language-button"
            className="ion-padding-end"
            slot="end"
            fill="clear"
            onClick={() => setShowSelector(!showSelector)}
          >
            <IonIcon
              slot="start"
              className="flag"
              src={`./assets/images/${lang === 'es' ? 'mx' : 'ca'}.svg`}
            />
            {t(lang === 'es' ? 'ChangeLngEs' : 'ChangeLngEn')}
          </IonButton>
        </IonToolbar>
        <LanguageSelector
          isVisible={showSelector}
          langSelected={(newLang) => {
            setLang(newLang);
            setShowSelector(false);
          }}
        />
      </IonHeader>
    </>
  );
};

export default Header;
