/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { IonAvatar, IonIcon } from '@ionic/react';
import { camera } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import UpdateAvatar from '../../../components/updateAvatar/UpdateAvatar';
import { AppContextState } from '../../../state/AppContext';
import { userProfileImage } from '../../../utils';

const ProfileHeader: React.FC = () => {
  const contextState = useContext(AppContextState);
  const { type, uuid } = contextState.profile.user_profile;
  const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false);
  return (
    <div className="profileBanner">
      <div className="userProfilePhotoContainer">
        <div className="avatarContainer">
          <div className="avatar">
            <IonAvatar>
              <img
                src={userProfileImage(type, uuid)}
                alt="user avatar"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = 'assets/images/logo.png';
                  currentTarget.style.objectFit = 'contain';
                  currentTarget.style.background = '#ffffff';
                  currentTarget.style.padding = '0.8rem';
                }}
              />
              {/* <IonIcon
                color="light"
                className="selectAvatarIcon customCursor"
                onClick={() => setShowAvatarModal(true)}
                icon={camera}
              /> */}
            </IonAvatar>
          </div>
        </div>
      </div>
      <UpdateAvatar
        showModalFn={showAvatarModal}
        setShowAvatarModal={(value) => setShowAvatarModal(value)}
      />
    </div>
  );
};

export default ProfileHeader;
