/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { IonAvatar, IonIcon, IonText } from '@ionic/react';
import { camera } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { userProfileImage } from '../../utils';
import UpdateAvatar from '../updateAvatar/UpdateAvatar';

interface MUCprops {
  userName: string;
  userType: string;
  userUuid: string;
}

const MenuUserCard: React.FC<MUCprops> = ({ userName, userType, userUuid }) => {
  const { t } = useTranslation();
  const [showAvatarModal, setShowAvatarModal] = useState<boolean>(false);

  return (
    <div className="userProfileInfo">
      <IonAvatar className="userProfileImage">
        <img
          src={userProfileImage(userType, userUuid)}
          alt="user"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = 'assets/images/logo.png';
            currentTarget.style.objectFit = 'contain';
            currentTarget.style.background = '#ffffff';
            currentTarget.style.padding = '0.8rem';
          }}
        />
        {/* <IonIcon
          color="light"
          className="selectAvatarIcon customCursor"
          onClick={() => setShowAvatarModal(true)}
          icon={camera}
        /> */}
      </IonAvatar>
      <IonText color="primary">
        <p>{t('menuWelcomeName', { name: userName })}</p>
      </IonText>
      <hr />
      <UpdateAvatar
        showModalFn={showAvatarModal}
        setShowAvatarModal={(value) => setShowAvatarModal(value)}
      />
    </div>
  );
};

export default MenuUserCard;
