/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import {
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonAvatar,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatDate from '../../hooks/useFormatDate';
import { Balanceitem } from '../../interfaces';
import { AppointmentDetail } from '../../interfaces/clientInterfaces';
import { AppContextState } from '../../state/AppContext';
import { userProfileImage } from '../../utils';
import ModalWrapper from '../modalWrapper/ModalWrapper';
import BalanceModal from './BalanceModal';
import './table.scss';

type Service = Balanceitem & AppointmentDetail;
type SelectableSegments = string | undefined;

const ExpertBalances: React.FC = () => {
  const { t } = useTranslation();

  const contextState = useContext(AppContextState);
  const { formatIsoDate } = useFormatDate();

  const [mentoring, setMentoring] = useState<Service[]>([]);
  const [coaching, setCoaching] = useState<Service[]>([]);
  const [express, setExpress] = useState<Service[]>([]);
  const [modalPayment, setModalPayment] = useState<Service | undefined>(
    undefined,
  );

  const [activeSegment, setActiveSegment] = useState<SelectableSegments>(
    'express',
  );

  const { balances, allAppointments, services } = contextState;
  const balanceItems = balances?.balance_items;

  useEffect(() => {
    if (balanceItems && allAppointments) sortPayments();
  }, [balanceItems, allAppointments]);

  const sortPayments = () => {
    let findElement: AppointmentDetail | undefined;
    if (balanceItems) {
      balanceItems.forEach((element) => {
        findElement = allAppointments
          && allAppointments.find((o) => o.id === element.appointment_id);
        if (findElement) {
          const service = services
            && findElement
            && services.find((o) => o.id === findElement?.service_id);
          const newElement = { ...findElement, ...element };
          if (service) {
            if (service.type.includes('xpres')) {
              const isServiceInArray = express.find(
                (o) => o.appointment_id === newElement.appointment_id,
              );
              if (!isServiceInArray) setExpress((rest) => [...rest, newElement]);
            } else if (service.type.includes('entorin')) {
              const isServiceInArray = mentoring.find(
                (o) => o.appointment_id === newElement.appointment_id,
              );
              if (!isServiceInArray) setMentoring((rest) => [...rest, newElement]);
            } else {
              const isServiceInArray = coaching.find(
                (o) => o.appointment_id === newElement.appointment_id,
              );
              if (!isServiceInArray) setCoaching((rest) => [...rest, newElement]);
            }
          }
        }
      });
    }
  };

  const renderTable = (serviceArray: Service[]) => {
    if (serviceArray.length > 0) {
      return (serviceArray.map((serv) => (
        <tr key={serv.id} className="ion-padding">
          <td className="userCell" data-label={t('CP_L2')}>
            <IonAvatar
              className="ion-margin-bottom"
            >
              <img
                style={{ margin: 'auto' }}
                src={userProfileImage('client', serv.client.uid)}
                alt="user"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = 'assets/images/logo.png';
                  currentTarget.style.objectFit = 'contain';
                  currentTarget.style.background = '#ffffff';
                  currentTarget.style.padding = '0.8rem';
                }}
              />
            </IonAvatar>
            <p className="m-0">{serv.user_full_name}</p>
          </td>
          <td data-label={t('CP_L3')}>{formatIsoDate(serv.start_date)}</td>
          <td data-label={t('CP_L4')}>
            $
            {serv.amount.toFixed(2)}
            {' '}
            USD
          </td>
          <td data-label={t('CP_L5')}>
            <p className="m-0">{serv.paid ? t('CP_L7') : t('CP_L8')}</p>
          </td>
          <td>
            <IonButton
              fill="solid"
              color="secondary"
              shape="round"
              onClick={() => setModalPayment(serv)}
            >
              {t('CP_L6')}
            </IonButton>
          </td>
        </tr>
      )));
    }
    return null;
  };

  if (!balances) return null;

  return (
    <>
      <div className="section-title">
        <h2 className="pr-3">{t('SC_L1')}</h2>
        <span className="title-border" />
      </div>
      <div id="paymentTable" className="ion-padding">
        <IonSegment
          scrollable
          value={activeSegment}
          onIonChange={(e) => setActiveSegment(e.detail.value)}
        >
          <IonSegmentButton data-testid="express" value="express">
            <IonLabel>{t('express_service')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton data-testid="mentoring" value="mentoring">
            <IonLabel>{t('mentoring_service')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton data-testid="coaching" value="coaching">
            <IonLabel>{t('coaching_service')}</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        <IonGrid id="appSlider" className="ion-margin-top ion-no-padding">
          <IonRow>
            <IonCol>
              <table>
                <thead>
                  <tr>
                    <th>{t('CP_L2')}</th>
                    <th>{t('CP_L3')}</th>
                    <th>{t('CP_L4')}</th>
                    <th>{t('CP_L5')}</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {activeSegment === 'express' && renderTable(express)}
                  {activeSegment === 'mentoring' && renderTable(mentoring)}
                  {activeSegment === 'coaching' && renderTable(coaching)}
                </tbody>
              </table>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      {modalPayment
      && (
      <ModalWrapper wrapperClass="smallModal" isOpen={!!modalPayment} setIsOpen={() => setModalPayment(undefined)}>
        <BalanceModal payment={modalPayment} />
      </ModalWrapper>
      )}
    </>
  );
};

export default ExpertBalances;
